import React from "react";
import SEO from "../components/seo";
import image from "../images/404.svg";
import { Link } from "gatsby";

const NotFoundPage = () =>  (
  <>
     <SEO title={`404 - Digimeth.net`}/>
      <div className="notfound">
      <div className="notfound-box">
        <div className="image__container">
          <img src={image} alt="404 page" />
        </div>
        <h1>
        Oops! Page not found
        </h1>
        <Link to="/">
          <button type="button" className="digimeth-btn xl secondary-btn">
            Back to home page
          </button>
        </Link>
      </div>
    </div>
  </>

  )

export default NotFoundPage;